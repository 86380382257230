$(function(){
    console.log('Hello Brian Bravo!');

    AYNI.init();
    AYNI_MODALS.init();
    AYNI_WEBSITES.init();
});

var AYNI = {
    init: function() {
        // this.handleSamePageLiks();
        this.handleHeaderVisibility();
    },
    handleSamePageLiks: function() {
        $('body').on('click', 'a[href!="#"]', function(event){
            var $target = $('#' + event.target.href.split('#')[1]);

            $('html, body').stop().animate({
                scrollTop: $target.offset().top,
            }, 700, 'swing');
        });
    },
    handleContactFormSubmition: function() {
        $('form').on('submit', function() {
            var $form = $(this);
            var $submitButton = $form.find('[type=submit]');
            var data  = $form.serialize();
            var type  = $form.attr('method');
            var url   = $form.attr('action');

            $submitButton.text('Sending...');

            $.ajax({
                type: type,
                url: url,
                data: data,
                done: function(response) {
                    $submitButton.text('Success!');
                    $submitButton.attr('disabled', 'disabled');
                    $submitButton.fadeOut();
                    $form.find('.form-successMessage').fadeIn();
                }
            });
        });
    },
    handleHeaderVisibility: function() {
        // grab an element
        var myElement = document.querySelector("header");
        // construct an instance of Headroom, passing the element
        var headroom  = new Headroom(myElement, {
          "offset": 500});
        // initialise
        headroom.init();
    }
}

var AYNI_MODALS = {
    init: function(){
        this.setUpTriggerEvents();
    },
    setUpTriggerEvents: function() {
        $('body').on('click', '[data-ayni-modal-trigger]', function(){
            $($(this).data('ayni-modal-target')).toggleClass('active');
            $('.main').toggleClass('blurred');
            $('body').toggleClass('no-overflow')
        });
    }
};

var AYNI_WEBSITES = {
    init: function() {
    }
};
